<template>
  <v-main class="fill-height">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
        >
          <v-card
            height="400"
            color="transparent"
            flat
          >
            <div class="notfound">
              <div class="notfound-404">
                <div />
                <h1>{{ error }}</h1>
              </div>
              <h2>{{ title }}</h2>
              <p>
                {{ message }}
              </p>
              <router-link :to="{ name: 'MainPage' }">
                вернуться на главную
              </router-link>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    messages: {
      403: {
        title: "Доступ запрещён",
        message:
          "Страница существует, но по какому-то недоразумению у вас нет прав на её просмотр.",
      },
      404: {
        title: "Страница не найдена",
        message:
          "Невероятно, но данная страница никогда не существовала в реальности или сбежала в параллельную Вселенную.",
      },
      500: {
        title: "Внутренняя ошибка сервера",
        message:
          "Что-то пошло не так при отправке запроса на сервер. Скоро всё починим!",
      },
    },
  }),
  computed: {
    error() {
      const error = this.$route.name.slice(5, 8);
      return ["403", "404", "500"].includes(error) ? error : null;
    },
    title() {
      return this.error ? this.messages[this.error].title : "";
    },
    message() {
      return this.error ? this.messages[this.error].message : "";
    },
  },
};
</script>

<style scope>
.notfound {
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.notfound .notfound-404 {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0px auto 50px;
}

.notfound .notfound-404 > div:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffa200;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 5px dashed #000;
  border-radius: 5px;
}

.notfound .notfound-404 > div:first-child:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
  border-radius: 5px;
}

.notfound .notfound-404 h1 {
  font-family: "Cabin", sans-serif;
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 90px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 33px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.notfound p {
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.notfound a {
  font-family: "Cabin", sans-serif;
  display: inline-block;
  padding: 10px 25px;
  background-color: #8f8f8f;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #2c2c2c;
}
</style>
